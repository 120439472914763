.instagramWrap{
    h3{
        font-size: 24px;
        margin-bottom: 30px;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li{
            flex: 0 0 32%;
            width: 32%;
            margin-bottom: 7px;
            img{
                width: 100%;
            }
        }
    }
}